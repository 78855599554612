:root {
  --primary: #00B0FF;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

::-webkit-scrollbar {
  width: 8px;
  display: none;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 8px;
}
 
::-webkit-scrollbar-thumb {
  background: #00B0FF; 
  border-radius: 8px;
}

::-webkit-scrollbar-thumb:hover {
  background: #00aeffb6; 
  border-radius: 10px;
}

.header-title {
  color: #00B0FF;
  font-weight: bold;
  font-size: 60px;
  text-align: center;
  overflow: hidden;
}

@keyframes slideInLeft {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slideInRight {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}


#blue_pattern {
  position: relative;
  top: -30px;
  float: right;
  width: 150px;
  height: 100%;
}

@media screen and (max-width:940px) {

  #blue_pattern {
    display: none;
  }

}

.fa-arrow-right {
  padding-left: 1em;
  transition: 0.3s;
}

.main-btn-white {
  font-size: 1em;
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
  display: inline-block;
  width: auto;
  line-height: 3em;
  text-align: center;
  padding: 0.5em 2em;
  position: relative;
  z-index: 1;
  background-color: transparent;
  border: 3px solid var(--primary);
  font-weight: 800;
  color: #fff;
  -webkit-transition: color .3s;
  transition: color .3s;
  -webkit-transition-delay: .1s;
          transition-delay: .1s;
}
.main-btn-white:hover {
  color: white;
  border: 3px solid var(--primary);
}

.main-btn-white:hover .fa-arrow-right {
  position: relative;
  transform: translateX(0.5em)
}

.main-btn-white:hover:before {
  height: 100%;
}
.main-btn-white:before {
  content: '';
  position: absolute;
  display: block;
  width: 100%;
  opacity: 1;
  bottom: 0;
  left: 0;
  z-index: -1;
  background-color: var(--primary);
  height: 0;
  -webkit-transition: height .3s;
  transition: height .3s;
}

.main-btn {
  font-size: 1em;
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
  display: inline-block;
  width: auto;
  line-height: 3em;
  text-align: center;
  padding: 0.5em 2em;
  position: relative;
  z-index: 1;
  background-color: transparent;
  border: 3px solid #fff;
  font-weight: 800;
  color: #fff;
  -webkit-transition: color .3s;
  transition: color .3s;
  -webkit-transition-delay: .1s;
          transition-delay: .1s;
}

.main-btn:hover {
  color: white;
  border: 3px solid var(--primary);
}

.main-btn:hover:before {
  height: 100%;
}

.main-btn:before {
  content: '';
  position: absolute;
  display: block;
  width: 100%;
  opacity: 1;
  bottom: 0;
  left: 0;
  z-index: -1;
  background-color: var(--primary);
  height: 0;
  -webkit-transition: height .3s;
  transition: height .3s;
}

.main-btn-blue {
  font-size: 1em;
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
  display: inline-block;
  width: auto;
  line-height: 3em;
  text-align: center;
  padding: 0.5em 2em;
  position: relative;
  z-index: 1;
  background-color: transparent;
  border: 3px solid var(--primary);
  font-weight: 800;
  color: var(--primary);
  -webkit-transition: color .3s;
  transition: color .3s;
  -webkit-transition-delay: .1s;
          transition-delay: .1s;
}

.main-btn-blue:hover {
  color: white;
  border: 3px solid var(--primary);
}

.main-btn-blue:hover:before {
  height: 100%;
}

.main-btn-blue:before {
  content: '';
  position: absolute;
  display: block;
  width: 100%;
  opacity: 1;
  bottom: 0;
  left: 0;
  z-index: -1;
  background-color: var(--primary);
  height: 0;
  -webkit-transition: height .3s;
  transition: height .3s;
}

.about-main-btn {
  width: 200px;
  margin: 0 auto;
}

.portfolio-main-btn {
  width: 250px;
  margin: 0 auto;
}

#portfolio-page .portfolio-main-btn {
  width: 250px;
  margin: 0 auto;
}

/* -----------------------  POPUP  --------------------------- */

.popup-bg {
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 1000;
  animation: 0.5s ease-out 0s 1 slideInRight;
  margin: 0 auto;
  width: 15em;
  height: 15em;
  border-radius: 300px 0px 0px 0px; 
  border-left: 2px solid var(--primary);
  border-top: 2px solid var(--primary);
  background-color: #fff;
  display: none;
}

#popup-right {
  background-color: transparent;
  position: relative;
  top: 2em;  
  width: 15em;
  border-radius: 300px 0px 0px 0px; 
  padding-left: 2em;
}

.popup h1 {
  padding-right: 0.5em;
  text-align: right;
  font-weight: 800;
  font-size: 3em;
  color: var(--primary);
}

.popup h2 {
  font-weight: 700;
  padding-bottom: 0.6em;
  margin-bottom: 0.6em;
  margin-top: -1em;
  text-align: right;
  font-size: 1.3em;
  padding-right: 1em;
  width: 90%;
  float: right;
  position: relative;
  top: 1em;
}

.popup-button {
  background-color: transparent;
  border: none;
  float: right;
  position: relative;
  top: 1em;
  padding-right: 1.5em;
  text-transform: uppercase;
  color: var(--primary);
  font-weight: 800;
  font-size: 1.2em;
  position: relative;
  transition: ease-in-out 0.3s;
}

.popup-button:hover { 
  position: relative;
  color: #00aeff92;
  transform: translateX(10px);
}

.popup-button i {
  padding-left: 0.8em;
}

#popup-form iframe {
  max-height: 300px;
}

#close-popup {
  border: none;
  padding: 0.1em;
  background-color: #fff;
  color: #000;
  height: 3em;
  width: 3em;
  border-radius: 50%;
  font-weight: 800;
  font-family: "Varela Round";
  position: relative;
  margin-bottom: -3em;
  float: right;
  transition: ease-in-out 0.3s;
  top: -3em;
  left: 1em;
}

#close-popup:hover { 
  background-color: var(--primary);
  color: #fff;
}

@media only screen and (max-width: 900px) {

  .popup-bg {
    display: none;
  }

}

.floating {  
  animation-name: floating;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

@keyframes floating {
  0% { transform: translate(0,  0px); }
  50%  { transform: translate(0, 15px); }
  100%   { transform: translate(0, -0px); }    
}

@import url('https://fonts.googleapis.com/css?family=Varela+Round');
@import url('https://fonts.googleapis.com/css?family=Montserrat');
@import url('https://fonts.googleapis.com/css?family=Space+Grotesk');
@import url('https://fonts.googleapis.com/css?family=Work+Sans');